import { PrivateRoute } from '@dimatech/features-core/lib/components/PrivateRoute';
import {
  AuthenticationContext,
  AuthenticationProvider,
} from '@dimatech/features-core/lib/features/authentication';
import {
  useGoogleAnalytics,
  useLaunchDarkly,
} from '@dimatech/features-core/lib/hooks';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useRedirectToMaintenance } from '@dimatech/shared/lib/hooks';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { dimiosActions } from 'api/dimiosSlice';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { ReactNode, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AdministrateAccount } from 'views/AdministrateAccount';
import { AdministrateMessageTemplate } from 'views/AdministrateMessageTemplate';
import { AdministrateMessages } from 'views/AdministrateMessages';
import { AdministrateOrganisation } from 'views/AdministrateOrganisation';
import { AdministrateRespondents } from 'views/AdministrateRespondents';
import { AdministrateRespondentsFailedMessages } from 'views/AdministrateRespondentsFailedMessages';
import { AdministrateSchedule } from 'views/AdministrateSchedule';
import { AdministrateStories } from 'views/AdministrateStories';
import { AdministrateUsers } from 'views/AdministrateUsers';
import { AdministrateViewStatus } from 'views/AdministrateViewStatus';
import { AllResult } from 'views/AllResult';
import { CookieInformation } from 'views/CookieInformation';
import { Customer } from 'views/Customer';
import { Demo } from 'views/Demo';
import { ForgotPassword } from 'views/ForgotPassword';
import { GlobalAdministrateAppConfig } from 'views/GlobalAdministrateAppConfig';
import { GlobalAdministrateOverview } from 'views/GlobalAdministrateOverview';
import { GlobalAdministrateReports } from 'views/GlobalAdministrateReports';
import { Help } from 'views/Help';
import { InternalComparison } from 'views/InternalComparison';
import { Login } from 'views/Login';
import { Maintenance } from 'views/Maintenance';
import { Message } from 'views/Message';
import { NextStep } from 'views/NextStep';
import { OrderDikios } from 'views/OrderDikios';
import { OrderDios } from 'views/OrderDios';
import { OrderPios } from 'views/OrderPios';
import { Overview } from 'views/Overview';
import { QuestionAnalyze } from 'views/QuestionAnalyze';
import { Register } from 'views/Register';
import { ReleaseNotes } from 'views/ReleaseNotes';
import { Reports } from 'views/Reports';
import { ResetPassword } from 'views/ResetPassword';
import { Stories } from 'views/Stories';
import { StyleGuide } from 'views/StyleGuide';
import { Unauthorized } from 'views/Unauthorized';
import { UserProfile } from 'views/UserProfile';
import { VideoLibrary } from 'views/VideoLibrary';
import { Welcome } from 'views/Welcome';

// TODO: Fix, split code
/* eslint-disable max-lines-per-function */

const App = (): JSX.Element => {
  return (
    <AuthenticationProvider>
      <WithAuthentication>
        <LocalizationProvider>
          <TrackingProvider>
            <ThemeProvider themes={themes}>
              <ApplicationProvider>
                <GlobalStyles />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/demo" element={<Demo />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/cookies" element={<CookieInformation />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/reset-password/:resetToken"
                    element={<ResetPassword />}
                  />
                  <Route path="/welcome/:resetToken" element={<Welcome />} />
                  <Route path="/style-guide" element={<StyleGuide />} />

                  <Route
                    path="user-profile"
                    element={
                      <PrivateRoute>
                        <UserProfile />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/customer/:customerId"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Customer />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="allresult"
                    element={
                      <PrivateRoute>
                        <AllResult />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="question-analyze"
                    element={
                      <PrivateRoute>
                        <QuestionAnalyze />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="stories"
                    element={
                      <PrivateRoute>
                        <Stories />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="internal-comparison"
                    element={
                      <PrivateRoute>
                        <InternalComparison />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="reports"
                    element={
                      <PrivateRoute>
                        <Reports />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/help"
                    element={
                      <PrivateRoute>
                        <Help />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/video-library"
                    element={
                      <PrivateRoute>
                        <VideoLibrary />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/video-library/:videoId"
                    element={
                      <PrivateRoute>
                        <VideoLibrary />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/release-notes"
                    element={
                      <PrivateRoute>
                        <ReleaseNotes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/release-notes/:version"
                    element={
                      <PrivateRoute>
                        <ReleaseNotes />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/next-step"
                    element={
                      <PrivateRoute>
                        <NextStep />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/organisation"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateOrganisation />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/respondents"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateRespondents />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/respondents-failed-messages"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateRespondentsFailedMessages />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/schedule"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateSchedule />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/message-template/"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateMessageTemplate />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/view-status"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateViewStatus />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/stories"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.app.dimios.isStoriesModerationEnabled
                        }
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                        ]}
                      >
                        <AdministrateStories />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/account"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                          CommonRoles.DemoUser,
                        ]}
                      >
                        <AdministrateAccount />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/messages"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.shared
                            .isUserMessagesForCustomersEnabled
                        }
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <AdministrateMessages />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="administrate/message/:userMessageId"
                    element={
                      <PrivateRoute
                        requireFlag={
                          flags.permanent.shared
                            .isUserMessagesForCustomersEnabled
                        }
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <Message />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/users"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <AdministrateUsers />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/administrate/users/:role"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <AdministrateUsers />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/global-administrate/overview"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <GlobalAdministrateOverview />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/global-administrate/appconfig"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <GlobalAdministrateAppConfig />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/global-administrate/reports"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.GlobalAdmin,
                          CommonRoles.Researcher,
                        ]}
                      >
                        <GlobalAdministrateReports />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-pios"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <OrderPios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-dios"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <OrderDios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/order-dikios"
                    element={
                      <PrivateRoute
                        requireRole={[
                          CommonRoles.CustomerAdmin,
                          CommonRoles.CustomerAccountAdmin,
                          CommonRoles.GlobalAdmin,
                        ]}
                      >
                        <OrderDikios />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <Overview />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </ApplicationProvider>
            </ThemeProvider>
          </TrackingProvider>
        </LocalizationProvider>
      </WithAuthentication>
    </AuthenticationProvider>
  );
};

export default App;

// We need to separate this from App so we can access AuthenticationContext
const WithAuthentication = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { accessToken, logout } = useContext(AuthenticationContext);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useLaunchDarkly();
  useGoogleAnalytics();
  useRedirectToMaintenance(flags.permanent.app.dimios.isMaintenanceOn, logout);

  const isDemoEnabled = useFlags()[flags.permanent.app.dimios.isDemoEnabled];

  useEffect(() => {
    if (!isDemoEnabled && accessToken.isInRole(CommonRoles.DemoUser)) {
      logout();
      navigate('/');
    }

    dispatch(dimiosActions.resetFilter());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoEnabled, accessToken.token]);

  return <>{children}</>;
};
