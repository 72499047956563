import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { selectFilterByEntity } from 'api/dimiosSlice';
import { getHistorical } from 'api/report/reportHistoricalSlice';
import { Report, Reports } from 'components/Reports';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Report as ReportModel } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const HistoricalReportList = ({
  reports,
  entityName,
}: {
  reports?: ReportModel[];
  entityName?: string;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent
        id="tt-export-historical-download"
        title={t('Reports.Download.TooltipTitle')}
        text={t('Reports.Download.Tooltip')}
      />

      <Reports>
        <Heading3 style={{ marginBottom: 10 }}>
          {entityName
            ? t('Reports.Reports.Historical.Title', {
                entityName,
              })
            : t('Reports.Reports.Historical.TitleNoEntity')}
        </Heading3>

        {!entityName && (
          <span style={{ fontStyle: 'italic' }}>
            {t('Reports.Reports.Historical.TextNoEntity')}
          </span>
        )}

        {entityName && !reports?.length && (
          <span style={{ fontStyle: 'italic' }}>
            {t('Reports.Reports.Historical.NoData', {
              entityName,
            })}
          </span>
        )}

        {reports &&
          entityName &&
          reports?.map((report, index) => {
            return (
              <HistoricalReport
                key={index}
                report={report}
                entityName={entityName}
              />
            );
          })}
      </Reports>
    </>
  );
};

HistoricalReportList.displayName = 'HistoricalReportList';

const HistoricalReport = ({
  report,
  entityName,
}: {
  report: ReportModel;
  entityName: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filterByEntity = useAppSelector(selectFilterByEntity);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (report: ReportModel) => {
    setIsDownloading(true);

    dispatch(
      getHistorical({
        entityId: filterByEntity.entityId,
        reportId: report.id,
        fileName: report.fileName,
        name: report.name,
      })
    ).finally(() => setIsDownloading(false));
  };

  return (
    <Report
      report={{
        ...report,
        description: t('Reports.Reports.Historical.Description', {
          entityName,
        }),
      }}
      isDownloading={isDownloading}
      handleDownload={handleDownload}
    />
  );
};
