var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { YearPicker } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { sub } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGetStatisticsQuery } from '../../../api/account/accountApi';
import { StatisticsMessages } from './StatisticsMessages';
import { StatisticsResponses } from './StatisticsResponses';
export var Statistics = function () {
    var t = useTranslation().t;
    var _a = useState(sub(new Date(), { years: 1 }).getFullYear().toString()), startYear = _a[0], setStartYear = _a[1];
    var _b = useState(new Date().getFullYear().toString()), endYear = _b[0], setEndYear = _b[1];
    var _c = useGetStatisticsQuery({
        startYear: startYear,
        endYear: endYear,
    }), data = _c.data, isFetching = _c.isFetching;
    return (_jsxs(_Fragment, { children: [_jsxs(HeaderStyle, { children: [_jsx(Heading3, { children: t('GlobalAdministrate.Overview.Statistics.Title') }), _jsx(YearPicker, { name: "startYear", date: startYear, setDate: function (date) {
                            return setStartYear((date === null || date === void 0 ? void 0 : date.getFullYear().toString()) || '');
                        } }), _jsx(YearPicker, { name: "endYear", date: endYear, setDate: function (date) {
                            return setEndYear((date === null || date === void 0 ? void 0 : date.getFullYear().toString()) || '');
                        } })] }), isFetching && _jsx(LoaderSmall, { children: t('Common.UI.Loading') }), !isFetching && (data === null || data === void 0 ? void 0 : data.length) === 0 && (_jsx("div", __assign({ style: { marginTop: 20 } }, { children: t('GlobalAdministrate.Overview.Statistics.NoItems') }))), !isFetching && data && (data === null || data === void 0 ? void 0 : data.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(Card, __assign({ title: t('GlobalAdministrate.Overview.Statistics.Messages.Title') }, { children: _jsx(CardBody, __assign({ isLoading: isFetching }, { children: _jsx(StatisticsMessages, { data: data, name: t('GlobalAdministrate.Overview.Statistics.Messages.Title') }) })) })), _jsx(Card, __assign({ title: t('GlobalAdministrate.Overview.Statistics.Responses.Title') }, { children: _jsx(CardBody, __assign({ isLoading: isFetching }, { children: _jsx(StatisticsResponses, { data: data, name: t('GlobalAdministrate.Overview.Statistics.Responses.Title') }) })) }))] }))] }));
};
Statistics.displayName = 'Statistics';
var HeaderStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  h3 {\n    margin-right: 20px;\n  }\n\n  > div {\n    margin-right: 20px;\n\n    input {\n      width: 90px;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  h3 {\n    margin-right: 20px;\n  }\n\n  > div {\n    margin-right: 20px;\n\n    input {\n      width: 90px;\n    }\n  }\n"])));
var templateObject_1;
