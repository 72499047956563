import { useTitle } from '@dimatech/shared/lib/hooks';
import { AdministrateUsers as AdministrateUsersFeature } from 'features/administrate-users';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { View } from 'views';

export const AdministrateUsers = (): JSX.Element | null => {
  const { t } = useTranslation();

  const location = useLocation();
  const { setTitle } = useTitle();

  useEffect(() => {
    let title = t('UserRoles.CustomerAdmin.Title');

    switch (true) {
      case location.pathname.endsWith('customer-admin'):
        title = t('UserRoles.CustomerAdmin.Title');
        break;
      case location.pathname.endsWith('reader'):
        title = t('UserRoles.Reader.Title');
        break;
    }

    setTitle(
      `${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.Users.Title'
      )} - ${title}`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <View title={t('Administrate.Users.Title')}>
      <AdministrateUsersFeature />
    </View>
  );
};

AdministrateUsers.displayName = 'AdministrateUsers';
