var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertSuccess } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Button, ButtonSecondary, Buttons, } from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { useContext, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { useForgotPasswordMutation } from '../../../api/authentication/authenticationApi';
import { CommonRoles } from '../../../models';
import { AuthenticationContext } from '../AuthenticationProvider';
export var SendForgotPasswordEmail = function (_a) {
    var email = _a.email, style = _a.style;
    var t = useTranslation().t;
    var id = useId();
    var accessToken = useContext(AuthenticationContext).accessToken;
    var _b = useForgotPasswordMutation(), forgotPassword = _b[0], _c = _b[1], error = _c.error, isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    var _d = useState(false), isShowingSendForgotPasswordModal = _d[0], setIsShowingSendForgotPasswordModal = _d[1];
    if (!accessToken.user || !accessToken.isInRole(CommonRoles.GlobalAdmin)) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt_forgot_password_".concat(id), title: t('SendForgotPasswordEmail.TooltipTitle'), text: t('SendForgotPasswordEmail.Tooltip', { email: email }) }), _jsx(BadgeMini, __assign({ style: __assign({ marginLeft: 7 }, style), "data-tip": true, "data-for": "tt_forgot_password_".concat(id), onClick: function () { return setIsShowingSendForgotPasswordModal(true); }, disabled: isLoading || isSuccess }, { children: _jsx(BsFillEnvelopeFill, {}) })), isShowingSendForgotPasswordModal && (_jsxs(Modal, __assign({ handleKeyEsc: function () { return setIsShowingSendForgotPasswordModal(false); }, title: t('SendForgotPasswordEmail.Send.Title') }, { children: [isSuccess && (_jsx(AlertSuccess, { children: t('SendForgotPasswordEmail.Send.Successful', {
                            email: email,
                        }) })), error && (_jsx(AlertErrors, { error: error, translationPath: "SendForgotPasswordEmail.Send.ValidationError" })), !isSuccess &&
                        !error &&
                        t('SendForgotPasswordEmail.Send.ToEmail', {
                            email: email,
                        }), _jsxs(Buttons, { children: [!isSuccess && (_jsxs(_Fragment, { children: [_jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsShowingSendForgotPasswordModal(false); } }, { children: t('Common.Form.Cancel') })), _jsx(Button, __assign({ disabled: isLoading, type: "button", onClick: function () {
                                            forgotPassword({ email: email });
                                            trackEvent(EventType.AuthPasswordForgot);
                                        } }, { children: t('Common.UI.Ok') }))] })), isSuccess && (_jsx(_Fragment, { children: _jsx(ButtonSecondary, __assign({ type: "button", onClick: function () { return setIsShowingSendForgotPasswordModal(false); } }, { children: t('Common.UI.Close') })) }))] })] })))] }));
};
SendForgotPasswordEmail.displayName = 'SendForgotPasswordEmail';
