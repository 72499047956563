var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { listToTree } from '@dimatech/shared/lib/utils';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
export var SelectEntity = function (props) {
    var t = useTranslation().t;
    var isLoading = props.isLoading, data = props.data, entityId = props.entityId, setEntityId = props.setEntityId, placeholder = props.placeholder, style = props.style, filter = props.filter, autoFocus = props.autoFocus, initialSetUnselected = props.initialSetUnselected, isOnlyVisibleEntitiesUsed = props.isOnlyVisibleEntitiesUsed;
    var _a = useState(), tree = _a[0], setTree = _a[1];
    var toOptions = function (entities, depth) {
        if (depth === void 0) { depth = 0; }
        var options = entities.map(function (entity) { return (_jsxs(Fragment, { children: [_jsxs("option", __assign({ value: entity.id }, { children: ['\u00A0'.repeat(depth * 2), depth > 0 ? '- ' : '', entity.name] })), toOptions(entity.children, depth + 1)] }, entity.id)); });
        return options;
    };
    useEffect(function () {
        if (data) {
            var entities_1 = isOnlyVisibleEntitiesUsed
                ? data.filter(function (entity) { return !entity.isReadOnly; })
                : data;
            var orphans = entities_1
                .filter(function (entity) { return !entities_1.some(function (child) { return entity.parentId === child.id; }); })
                .map(function (orphan) { return orphan.parentId; });
            setTree(listToTree(entities_1, orphans, filter));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isOnlyVisibleEntitiesUsed]);
    useEffect(function () {
        // If only one root, set it as selected
        if (initialSetUnselected === undefined &&
            !entityId &&
            tree &&
            tree.length === 1) {
            setEntityId(tree[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree]);
    if (isLoading) {
        return (_jsx("div", __assign({ style: { margin: 10 } }, { children: _jsx(LoaderSmall, {}) })));
    }
    return (_jsxs(Select, __assign({ autoFocus: !!autoFocus, value: entityId, onChange: function (e) { return setEntityId(e.currentTarget.value); }, style: style }, { children: [(placeholder || (tree === null || tree === void 0 ? void 0 : tree.length) !== 1) && (_jsx("option", __assign({ value: "" }, { children: placeholder ? placeholder : t('Search.AnyEntity') }))), tree && toOptions(tree)] })));
};
SelectEntity.displayName = 'SelectEntity';
