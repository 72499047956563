import { Chart } from '@dimatech/features-core/lib/components/Chart';
import {
  Heatmap,
  HeatmapConfig,
} from '@dimatech/shared/lib/components/heatmap';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import i18n from 'localization';
import { CategoryByCustomer, DataItem, DataPlot } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { CategoryRangeDetails } from './CategoryRangeDetails';

export const ResultPerCategory = withTheme(
  ({
    theme,
    categoryByCustomers,
    name,
  }: {
    theme: Theme;
    categoryByCustomers?: CategoryByCustomer[];
    name: string;
  }): JSX.Element | null => {
    const cellSize = 10;
    const cellSizePixels = window.innerWidth < 600 ? 25 : 32;

    const { t } = useTranslation();
    const [data, setData] = useState<DataPlot[]>();

    const [showDetailsFor, setShowDetailsFor] = useState<
      CategoryByCustomer & { similar: CategoryByCustomer[] }
    >();

    const formatter = (value: number) => `${value}-${value + cellSize} %`;
    const formatterZ = (value: number) => value.toLocaleString(i18n.language);
    const formatterDetails = (value: number) => formatAsPercent(value);

    const config: HeatmapConfig = {
      gridSize: { x: 10, y: 10 },
      cellSize,
      cellSizePixels,
      calcX: (index: number) => index * 10,
      calcY: (index: number) => index * 10,
      colorFrom: theme.colors.surface,
      colorTo: theme.colors.primary,
      tooltip: {
        showDetails: true,
        showDetailsX: true,
        showDetailsY: true,
        title: t('AllResult.ResultPerCategory.NoOfOrganisations'),
        labelX: t('CommonTerms.DigitalHeritage'),
        labelY: t('CommonTerms.DigitalAbility'),
        formatterX: formatter,
        formatterY: formatter,
        formatterZ,
        formatterDetailsX: formatterDetails,
        formatterDetailsY: formatterDetails,
      },
    };

    useEffect(() => {
      if (categoryByCustomers) {
        const mapped = categoryByCustomers.map((category) => {
          return {
            id: category.customerId,
            x: (category.heritage?.percentage ?? 0) * 100,
            y: (category.ability?.percentage ?? 0) * 100,
            z: 1,
            label: category.organization,
          } as DataPlot;
        });

        setData(mapped);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryByCustomers]);

    const handleClick = (item: DataItem) => {
      const categoryByCustomer = categoryByCustomers?.find(
        (categoryByCustomer) => categoryByCustomer.customerId === item.id
      );

      if (!categoryByCustomer) {
        return;
      }

      const similar = categoryByCustomers?.filter(
        (categoryByCustomer) =>
          item.items?.findIndex(
            (inner) => inner.id === categoryByCustomer.customerId
          ) !== -1
      );

      if (similar) {
        setShowDetailsFor({ ...categoryByCustomer, similar });
      }
    };

    return (
      <>
        <Chart>
          <>
            {categoryByCustomers && data && data.length === 0 && (
              <div style={{ fontSize: theme.fonts.size.base }}>
                {t('AllResult.NoData')}
              </div>
            )}

            {data && data.length > 0 && (
              <Heatmap
                data={data ?? []}
                config={config}
                handleClick={handleClick}
                name={name}
              />
            )}
          </>
        </Chart>

        {showDetailsFor && (
          <CategoryRangeDetails
            showDetailsFor={showDetailsFor}
            setShowDetailsFor={setShowDetailsFor}
            title={t('AllResult.ResultPerCategory.Title')}
          />
        )}
      </>
    );
  }
);

ResultPerCategory.displayName = 'ResultPerCategory';
