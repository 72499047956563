import { AdministrateMessageTemplate as AdministrateMessageTemplateFeature } from '@dimatech/features-survey/lib/features/administrate-message-template';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateMessageTemplate = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.MessageTemplate.Title'
      )}`}
    >
      <AdministrateMessageTemplateFeature />
    </View>
  );
};

AdministrateMessageTemplate.displayName = 'AdministrateMessageTemplate';
