import { Loader } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

const VideoLibraryComponent = lazy(
  () => import('@dimatech/shared/lib/components/video-library/VideoLibrary')
);

export const VideoLibrary = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <ViewHeader>
        <Heading1>{t('VideoLibrary.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody>{t('VideoLibrary.Description')}</CardBody>
      </Card>

      <Card width="100%">
        <CardBody>
          <Suspense fallback={<Loader />}>
            <VideoLibraryComponent />
          </Suspense>
        </CardBody>
      </Card>
    </View>
  );
};

VideoLibrary.displayName = 'VideoLibrary';
