import { config } from '@dimatech/shared/lib/config';
import { getLocale } from '@dimatech/shared/lib/localization';
import { getTheme } from '@dimatech/shared/lib/themes';
import { getCookie, storeCookie } from '@dimatech/shared/lib/utils';
import { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { AuthenticationContext } from '../features/authentication/AuthenticationProvider';
import { CommonRoles } from '../models';
// This is used to identify current user with google analytics
var GaConsentCookieName = 'consent-ga';
export var useGoogleAnalytics = function (autoInitializeWithConsent) {
    if (autoInitializeWithConsent === void 0) { autoInitializeWithConsent = true; }
    var accessToken = useContext(AuthenticationContext).accessToken;
    var getHasGivenConsent = function () {
        var gaConsentCookie = getCookie(GaConsentCookieName);
        return !!gaConsentCookie;
    };
    var getHasSavedConsent = function () {
        var gaConsentCookie = getCookie(GaConsentCookieName);
        return gaConsentCookie !== undefined;
    };
    var setHasGivenConsent = function (hasGivenConsent) {
        storeCookie(GaConsentCookieName, hasGivenConsent.toString());
    };
    var hasGivenConsent = autoInitializeWithConsent || getHasGivenConsent();
    var user = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user;
    var initialize = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var hasGivenConsent = autoInitializeWithConsent || getHasGivenConsent();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window["ga-disable-".concat(config.googleAnalytics.key)] = !hasGivenConsent;
        if (!ReactGA.isInitialized) {
            ReactGA.initialize(config.googleAnalytics.key, {
                gtagOptions: {
                    user_id: (_a = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _a === void 0 ? void 0 : _a.id,
                    // NOTE: Enable when debugging
                    // Read more: https://support.google.com/analytics/answer/7201382?hl=en
                    debug_mode: config.googleAnalytics.debugMode,
                },
            });
        }
        ReactGA.set({
            user_properties: {
                user_locale: getLocale(),
                user_theme: getTheme(),
            },
        });
        var isInRole = function (role) {
            return accessToken ? accessToken.isInRole(role).toString() : 'false';
        };
        if (accessToken) {
            ReactGA.set({
                user_properties: {
                    user_id: (_c = (_b = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null,
                    customer_id: (_e = (_d = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _d === void 0 ? void 0 : _d.currentCustomer.id) !== null && _e !== void 0 ? _e : null,
                    customer_type: (_g = (_f = accessToken === null || accessToken === void 0 ? void 0 : accessToken.user) === null || _f === void 0 ? void 0 : _f.currentCustomer.customerType) !== null && _g !== void 0 ? _g : null,
                },
            });
            ReactGA.set({
                user_properties: {
                    // Add additional user properties if user is logged in
                    // cspell:disable
                    user_is_globaladmin: isInRole(CommonRoles.GlobalAdmin),
                    user_is_researcher: isInRole(CommonRoles.Researcher),
                    user_is_demouser: isInRole(CommonRoles.DemoUser),
                    user_is_custadmin: isInRole(CommonRoles.CustomerAdmin),
                    user_is_custaccountadmin: isInRole(CommonRoles.CustomerAccountAdmin),
                    // cspell:enable
                },
            });
        }
    };
    useEffect(function () {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, autoInitializeWithConsent, hasGivenConsent]);
    return {
        initialize: initialize,
        getHasSavedConsent: getHasSavedConsent,
        getHasGivenConsent: getHasGivenConsent,
        setHasGivenConsent: setHasGivenConsent,
    };
};
