import { AdministrateViewStatus as AdministrateViewStatusFeature } from 'features/administrate-view-status';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateViewStatus = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View
      title={`${t('Navigation.Administrate.Administrate')} - ${t(
        'Administrate.ViewStatus.Title'
      )}`}
    >
      <AdministrateViewStatusFeature />
    </View>
  );
};

AdministrateViewStatus.displayName = 'AdministrateViewStatus';
