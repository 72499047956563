var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CacheTagsEntity, CacheTagsOrganisationHistory, featuresCoreApi, } from '../featuresCoreApi';
var entityApi = featuresCoreApi.injectEndpoints({
    endpoints: function (build) { return ({
        /**
         * Get all entities for current customer
         */
        getEntities: build.query({
            // _customerId is only used for RTK-Q to know that it should invalidate
            // the cache and re-fetch when the user is switching customer
            query: function () { return 'entities'; },
            providesTags: [CacheTagsEntity.Entity],
            transformResponse: function (result) {
                return result.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
            },
        }),
        /**
         * Create new entity
         */
        addEntity: build.mutation({
            query: function (entity) { return ({
                url: "entities",
                method: 'POST',
                body: entity,
            }); },
            invalidatesTags: [
                CacheTagsEntity.Entity,
                CacheTagsOrganisationHistory.OrganisationHistory,
            ],
            transformResponse: function (result, _meta, arg) {
                return __assign(__assign({}, arg), result);
            },
        }),
        /**
         * Update entity
         */
        updateEntity: build.mutation({
            query: function (entity) { return ({
                url: "entities/".concat(entity.id),
                method: 'PUT',
                body: {
                    name: entity.name,
                },
            }); },
            invalidatesTags: [
                CacheTagsEntity.Entity,
                CacheTagsOrganisationHistory.OrganisationHistory,
            ],
        }),
        /**
         * Delete entity
         */
        deleteEntity: build.mutation({
            query: function (entityId) { return ({
                url: "entities/".concat(entityId),
                method: 'DELETE',
            }); },
            invalidatesTags: [
                CacheTagsEntity.Entity,
                CacheTagsOrganisationHistory.OrganisationHistory,
            ],
        }),
        /**
         * Move entity
         */
        moveEntity: build.mutation({
            query: function (_a) {
                var entityId = _a.entityId, parentId = _a.parentId;
                return ({
                    url: "entities/".concat(entityId, "/move/").concat(parentId),
                    method: 'PUT',
                });
            },
            invalidatesTags: [
                CacheTagsEntity.Entity,
                CacheTagsOrganisationHistory.OrganisationHistory,
            ],
            transformResponse: function (_result, _meta, arg) {
                return {
                    id: arg.entityId,
                    parentId: !arg.parentId ? null : arg.parentId,
                };
            },
        }),
    }); },
});
export var useGetEntitiesQuery = entityApi.useGetEntitiesQuery, useAddEntityMutation = entityApi.useAddEntityMutation, useUpdateEntityMutation = entityApi.useUpdateEntityMutation, useDeleteEntityMutation = entityApi.useDeleteEntityMutation, useMoveEntityMutation = entityApi.useMoveEntityMutation;
