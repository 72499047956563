import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { RespondentsFailedMessagesBadge } from '@dimatech/features-survey/lib/features/administrate-respondents/components/RespondentsFailedMessagesBadge';
import {
  NavigationHeader,
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Color } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsApp,
  BsArrowLeftRight,
  BsArrowRightCircle,
  BsBarChart,
  BsBook,
  BsCameraVideo,
  BsChat,
  BsClipboardCheck,
  BsDiagram3,
  BsEnvelopeFill,
  BsFileExcel,
  BsFileText,
  BsGear,
  BsGlobe,
  BsGraphUp,
  BsLightbulb,
  BsPeople,
  BsPlay,
  BsQuestionCircle,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

// eslint-disable-next-line max-lines-per-function
export const Navigation = (): JSX.Element => {
  const { accessToken } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));

  const isAdmin =
    accessToken.customerId &&
    (isCustomerAccountAdmin ||
      isGlobalAdmin ||
      accessToken.isInRole(CommonRoles.CustomerAdmin));

  const isReader =
    isAdmin ||
    isResearcher ||
    isDemoUser ||
    accessToken.isInRole(CommonRoles.Reader);

  const isStoriesModerationEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isStoriesModerationEnabled];
  const isUserMessagesForCustomersEnabled =
    useFlags()[flags.permanent.shared.isUserMessagesForCustomersEnabled];

  return (
    <>
      {isReader && (
        <>
          <NavigationItem
            $selected={
              location.pathname === '/overview' || location.pathname === '/'
            }
          >
            <BsBarChart />
            <Link to="/overview">{t('Navigation.Overview')}</Link>
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/internal-comparison'}
          >
            <BsArrowLeftRight />
            <Link to="/internal-comparison">
              {t('Navigation.InternalComparison')}
            </Link>
          </NavigationItem>

          <NavigationItem $selected={location.pathname === '/question-analyze'}>
            <BsLightbulb />
            <Link to="/question-analyze">
              {t('Navigation.QuestionAnalyze')}
            </Link>
          </NavigationItem>

          <NavigationItem $selected={location.pathname === '/stories'}>
            <BsBook />
            <Link to="/stories">{t('Navigation.Stories')}</Link>
          </NavigationItem>

          <NavigationItem $selected={location.pathname === '/allresult'}>
            <BsGlobe />
            <Link to="/allresult">
              {t(
                `Navigation.${
                  accessToken.isInternational ? 'SwedishResult' : 'AllResult'
                }`
              )}
            </Link>
          </NavigationItem>

          <NavigationItem $selected={location.pathname === '/reports'}>
            <BsFileExcel />
            <Link to="/reports">{t('Navigation.Reports')}</Link>
          </NavigationItem>

          <NavigationItem $selected={location.pathname === '/next-step'}>
            <BsArrowRightCircle />
            <Link to="/next-step">{t('Navigation.NextStep')}</Link>
          </NavigationItem>
        </>
      )}

      {(isAdmin || isResearcher || isCustomerAccountAdmin || isDemoUser) && (
        <>
          <NavigationSeparator />

          <NavigationHeader>
            {t('Navigation.Administrate.Administrate')}
          </NavigationHeader>

          <NavigationItem
            $selected={location.pathname === '/administrate/organisation'}
          >
            <BsDiagram3 />
            <Link to="/administrate/organisation">
              {t('Navigation.Administrate.Organisation')}
            </Link>
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/administrate/schedule'}
          >
            <BsPlay />
            <Link to="/administrate/schedule">
              {t('Navigation.Administrate.Schedule')}
            </Link>
          </NavigationItem>
        </>
      )}

      {isStoriesModerationEnabledFlagOn &&
        (isResearcher || isGlobalAdmin || isCustomerAccountAdmin) && (
          <NavigationItem
            $selected={location.pathname === '/administrate/stories'}
          >
            <BsBook />
            <Link to="/administrate/stories">
              {t('Navigation.Administrate.Stories')}
            </Link>
          </NavigationItem>
        )}

      {(isAdmin || isResearcher || isDemoUser) && (
        <>
          <NavigationItem
            $selected={location.pathname === '/administrate/respondents'}
          >
            <BsClipboardCheck />
            <Link to="/administrate/respondents">
              {t('Navigation.Administrate.Respondents')}
            </Link>
            <RespondentsFailedMessagesBadge />
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/administrate/message-template'}
          >
            <BsEnvelopeFill />
            <Link to="/administrate/message-template">
              {t('Navigation.Administrate.MessageTemplates')}
            </Link>
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/administrate/view-status'}
          >
            <BsGraphUp />
            <Link to="/administrate/view-status">
              {t('Navigation.Administrate.ViewStatus')}
            </Link>
          </NavigationItem>
        </>
      )}

      {isCustomerAccountAdmin && (
        <>
          <NavigationItem
            $selected={location.pathname === '/administrate/users'}
          >
            <BsPeople />
            <Link to="/administrate/users">
              {t('Navigation.Administrate.Users')}
            </Link>
          </NavigationItem>

          {isUserMessagesForCustomersEnabled && (
            <NavigationItem
              $selected={location.pathname === '/administrate/messages'}
            >
              <BsChat />
              <Link to="/administrate/messages">
                {t('Navigation.Administrate.Messages')}
              </Link>
            </NavigationItem>
          )}

          <NavigationItem
            $selected={location.pathname === '/administrate/account'}
          >
            <BsGear />
            <Link to="/administrate/account">
              {t('Navigation.Administrate.Account')}
            </Link>
          </NavigationItem>
        </>
      )}

      {isGlobalAdmin && (
        <>
          <NavigationSeparator />

          <NavigationHeader>
            {t('Navigation.GlobalAdministrate.GlobalAdministrate')}
          </NavigationHeader>

          <NavigationItem
            $selected={location.pathname === '/global-administrate/overview'}
          >
            <BsGraphUp />
            <Link to="/global-administrate/overview">
              {t('Navigation.GlobalAdministrate.Overview')}
            </Link>
          </NavigationItem>

          <NavigationItem
            $selected={location.pathname === '/global-administrate/reports'}
          >
            <BsFileExcel />
            <Link to="/global-administrate/reports">
              {t('Navigation.GlobalAdministrate.Reports')}
            </Link>
          </NavigationItem>
        </>
      )}

      {!isGlobalAdmin && isResearcher && (
        <>
          <NavigationSeparator />

          <NavigationHeader>
            {t('Navigation.Researcher.Researcher')}
          </NavigationHeader>

          <NavigationItem
            $selected={location.pathname === '/global-administrate/reports'}
          >
            <BsFileExcel />
            <Link to="/global-administrate/reports">
              {t('Navigation.Researcher.Reports')}
            </Link>
          </NavigationItem>
        </>
      )}

      <NavigationSeparator />

      <NavigationItem $selected={location.pathname === '/help'}>
        <BsQuestionCircle />
        <Link to="/help">{t('Navigation.Help')}</Link>
      </NavigationItem>

      <NavigationItem
        $selected={location.pathname.startsWith('/video-library')}
      >
        <BsCameraVideo />
        <Link to="/video-library">{t('Navigation.VideoLibrary')}</Link>
      </NavigationItem>

      <NavigationItem
        $selected={location.pathname.startsWith('/release-notes')}
      >
        <BsFileText />
        <Link to="/release-notes">{t('Navigation.ReleaseNotes')}</Link>
      </NavigationItem>

      <NavigationSeparator />

      <NavigationItem>
        <BsApp style={{ color: Color.RadicalRed }} />
        <ProductLink
          productId={config.products.pios.id as string}
          productUrl={config.products.pios.url}
          productName={t('Account.Pios.Name')}
          orderRoute="/order-pios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.SandyBrown }} />
        <ProductLink
          productId={config.products.dios.id as string}
          productUrl={config.products.dios.url}
          productName={t('Account.Dios.Name')}
          orderRoute="/order-dios"
        />
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.Amethyst }} />
        <ProductLink
          productId={config.products.dikios.id as string}
          productUrl={config.products.dikios.url}
          productName={t('Account.Dikios.Name')}
          orderRoute="/order-dikios"
        />
      </NavigationItem>

      {isGlobalAdmin && (
        <NavigationItem>
          <BsApp style={{ color: Color.DimatechBlue }} />
          <a href={config.products.admin.url}>{t('Account.Admin.Name')}</a>
        </NavigationItem>
      )}
    </>
  );
};

Navigation.displayName = 'Navigation';

const ProductLink = ({
  productId,
  productUrl,
  productName,
  orderRoute,
}: {
  productId: string;
  productUrl: string;
  productName: string;
  orderRoute: string;
}) => {
  const { accessToken } = useContext(AuthenticationContext);

  const isGlobalAdmin = accessToken.isInRole(CommonRoles.GlobalAdmin);
  const isResearcher = accessToken.isInRole(CommonRoles.Researcher);
  const isDemoUser = accessToken.isInRole(CommonRoles.DemoUser);

  const isCustomerAccountAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAccountAdmin));
  const isAdmin =
    accessToken.customerId &&
    (isGlobalAdmin || accessToken.isInRole(CommonRoles.CustomerAdmin));

  const customerHasAccess =
    (isCustomerAccountAdmin || isAdmin || isDemoUser || isResearcher) &&
    accessToken.hasCustomerProductAccess(productId);

  if (isGlobalAdmin) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (customerHasAccess && accessToken.hasUserProductAccess(productId)) {
    return <a href={productUrl}>{productName}</a>;
  }

  if (isAdmin || isCustomerAccountAdmin) {
    return <Link to={orderRoute}>{productName}</Link>;
  }

  return <a href={`${productUrl}/register`}>{productName}</a>;
};
