var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { EventType } from '@dimatech/shared/lib/models';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../api/entity/entitySlice';
import { filterActions, selectFilter } from '../api/filter/filterSlice';
import { SelectSurveyPeriod } from './SelectSurveyPeriod';
export var SelectExtendedFilters = function (_a) {
    var _b = _a.isPeriodFilterShown, isPeriodFilterShown = _b === void 0 ? true : _b, style = _a.style;
    var dispatch = useDispatch();
    var filter = useSelector(selectFilter);
    var accessToken = useContext(AuthenticationContext).accessToken;
    var customerId = useState(accessToken.customerId)[0];
    var handleChangeInstance = function (instance) {
        dispatch(entityActions.selectedEntity(undefined));
        dispatch(filterActions.setFilterInstance({ instance: instance }));
        trackEvent(EventType.FilterChange, {
            property: 'instance',
        });
    };
    useEffect(function () {
        if (customerId !== accessToken.customerId) {
            dispatch(filterActions.resetFilter());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken.customerId]);
    return (_jsx("div", __assign({ style: style }, { children: isPeriodFilterShown && (_jsx(SelectSurveyPeriod, { instance: filter.instance, setInstance: handleChangeInstance, isOnlyPeriodsWithDataShown: true, isCurrentPeriodAlwaysShown: true })) })));
};
SelectExtendedFilters.displayName = 'SelectExtendedFilters';
