import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  selectFilterByCustomer,
  selectFilterByEntityType,
} from 'api/dimiosSlice';
import {
  useGetDisagreementQuery,
  useGetOverviewByCustomerQuery,
  useGetOverviewQuery,
} from 'api/statistics/statisticsApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { Statistics } from 'features/overview/components/Statistics';
import { useAppSelector } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EntityType } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultBySubCategory } from './components/ResultBySubCategory';
import { ResultPerCategory } from './components/ResultPerCategory';
import { ResultPerType } from './components/ResultPerType';

export const AllResult = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const filterByEntityType = useAppSelector(selectFilterByEntityType);
  const filterByCustomer = useAppSelector(selectFilterByCustomer);

  const namespace = accessToken.isInternational ? 'SwedishResult' : 'AllResult';

  const isStandardDeviationEnabledFlagOn =
    useFlags()[flags.permanent.app.dimios.isStandardDeviationEnabled];

  const { data: overview, isFetching: isFetchingOverview } =
    useGetOverviewQuery(
      filterByEntityType.instance &&
        filterByCustomer.entityType &&
        accessToken.user?.id
        ? { filter: filterByEntityType, _userId: accessToken.user.id }
        : skipToken
    );

  const { data: categoryByCustomers, isFetching: isFetchingByCustomer } =
    useGetOverviewByCustomerQuery(
      filterByCustomer.instance && filterByCustomer.entityType
        ? {
            filter: filterByCustomer,
          }
        : skipToken
    );

  const { data: disagreement, isFetching: isFetchingDisagreement } =
    useGetDisagreementQuery(
      isStandardDeviationEnabledFlagOn &&
        filterByEntityType.instance &&
        accessToken.user?.id
        ? { filter: filterByEntityType, _userId: accessToken.user.id }
        : skipToken
    );

  /* Hide this for now since the numbers is a bit misleading */
  // const { data: progress, isFetching: isFetchingProgress } =
  //   useGetProgressQuery(
  //     filterByEntityType.instance && filterByEntityType.entityType
  //       ? { filter: filterByEntityType }
  //       : skipToken
  //   );

  const isEntityTypeSupportedByMap =
    filterByEntityType.entityType === EntityType.Region ||
    filterByEntityType.entityType === EntityType.Municipality;

  const hasData = !(
    overview &&
    overview.principles.length === 0 &&
    categoryByCustomers &&
    categoryByCustomers.length === 0 &&
    !isEntityTypeSupportedByMap
  );

  return (
    <>
      <ViewHeader>
        <Heading1>{t(`${namespace}.Title`)}</Heading1>
        <SelectExtendedFilters
          isEntityTypeFilterShown={true}
          isOnlyPeriodsWithDataShown={false}
        />
      </ViewHeader>

      <Card width="100%">
        <CardBody>{t(`${namespace}.Text`)}</CardBody>
      </Card>

      {!hasData && (
        <Card width="100%">
          <AlertInfo>{t('AllResult.NoData')}</AlertInfo>
        </Card>
      )}

      {hasData && (
        <>
          {overview && (
            <Card width="100%">
              <CardBody
                width="100%"
                isLoading={isFetchingOverview || isFetchingDisagreement}
              >
                <Statistics
                  principlesByCategory={overview.categories}
                  disagreement={disagreement?.[0].disagreement}
                />
              </CardBody>
            </Card>
          )}

          <ViewRow style={{ width: '100%' }}>
            <div>
              <Card
                title={t('AllResult.ResultPerCategory.Title')}
                canBeSavedAsPng={true}
              >
                <CardBody isLoading={isFetchingByCustomer}>
                  <ResultPerCategory
                    categoryByCustomers={categoryByCustomers}
                    name={t('AllResult.ResultPerCategory.Title')}
                  />
                </CardBody>
              </Card>

              <Card
                title={t('AllResult.ResultBySubCategory.Title')}
                canBeSavedAsPng={true}
              >
                <CardBody isLoading={isFetchingOverview}>
                  <ResultBySubCategory
                    principlesByCategory={overview?.categories}
                  />
                </CardBody>
              </Card>
            </div>

            <Card
              style={{ alignSelf: 'flex-start' }}
              title={t('AllResult.ResultPerType.Title')}
              canBeSavedAsPng={true}
            >
              <CardBody isLoading={isFetchingByCustomer}>
                <ResultPerType categoryByCustomers={categoryByCustomers} />
              </CardBody>
            </Card>
          </ViewRow>

          {/* Hide this for now since the numbers is a bit misleading */}
          {/* <Card width="100%" title={t('Overview.StatisticsResponseRate.Title')}>
            <CardBody width="100%" isLoading={isFetchingProgress}>
              <StatisticsResponseRate progress={progress} />
            </CardBody>
          </Card> */}
        </>
      )}
    </>
  );
};

AllResult.displayName = 'AllResult';
